import { useState, useEffect, useContext } from "react";
import { BaseUrlContext } from "../Contexts";
import { useAuth } from "../context/AuthContext";

const NumberOfTopics = 9;

const getQuestionsUrl = (baseUrl: string) => `${baseUrl}/questions`;
const getTopicsUrl = (baseUrl: string, userEmail: string, count: number) =>
  `${baseUrl}/users/${userEmail}/chat/topics/${count}`;

const useChatAPI = () => {
  const [questions, setQuestions] = useState<string[]>([]);
  const [topics, setTopics] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const baseUrl = useContext(BaseUrlContext);
  const { username } = useAuth();

  const fetchData = async (url: string, setData: (data: any) => void) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Error fetching data: ${response.statusText}`);
      }
      const data = await response.json();
      setData(data);
    } catch (error) {
      setError(error instanceof Error ? error.message : String(error));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(getQuestionsUrl(baseUrl), setQuestions);
  }, [baseUrl]);

  useEffect(() => {
    fetchData(getTopicsUrl(baseUrl, username, NumberOfTopics), (data) =>
      setTopics(data.top_topics)
    );
  }, [baseUrl, username]);

  return {
    questions,
    topics,
    isLoading,
    setIsLoading,
    error,
  };
};

export default useChatAPI;
