import React, { useContext } from "react";
import { Bot, Send, Trash2 } from "lucide-react";
import { ChatMessage, UserMessage } from "../../generated/protos/chat";
import "../../styles/Chat.css";
import ChatMessageDisplay from "./ChatMessageDisplay";
import HotTopics from "../HotTopics";
import ClickableQuestions from "../ClickableQuestions";
import ThinkingChat from "./ThinkingChat";
import useChatAPI from "../../hooks/useChatApi";
import { v4 as uuidv4 } from "uuid";
import { BaseUrlContext } from "../../Contexts";
import { useAuth } from "../../context/AuthContext";
import { useChat } from "../../context/ChatContext";

const sendMessageToApi = async (
  messages: ChatMessage[],
  baseUrl: string,
  userEmail: string,
  setLoading: (loading: boolean) => void
): Promise<ChatMessage> => {
  setLoading(true);
  try {
    const response = await fetch(`${baseUrl}/users/${userEmail}/chat`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(messages.map((message) => ChatMessage.toJSON(message))),
    });

    if (!response.ok) throw new Error(`API call failed: ${response.statusText}`);
    const rawJSON = await response.json();
    return ChatMessage.fromJSON(JSON.parse(rawJSON));
  } catch (error) {
    console.error("Error calling chat API:", error);
    throw error;
  } finally {
    setLoading(false);
  }
};

const Chat: React.FC = () => {
  const { messages, setMessages, sessionId, setSessionId } = useChat();
  const [userInput, setUserInput] = React.useState("");
  const [followUpQuestions, setFollowUpQuestions] = React.useState<string[]>([]);
  const { questions, topics, isLoading, setIsLoading } = useChatAPI();
  const baseUrl = useContext(BaseUrlContext);
  const { username } = useAuth();

  const handleSubmit = React.useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!userInput.trim()) return;

      const userMessage: UserMessage = {
        content: userInput.trim(),
        wasRecommended: false,
      };

      const userChatMessage: ChatMessage = {
        userMessage,
        sessionId,
        messageId: uuidv4(),
        asOfTimestamp: new Date(),
      };

      setMessages((prev: ChatMessage[]) => [...prev, userChatMessage]);
      setUserInput("");

      try {
        const updatedMessages = [...messages, userChatMessage];
        const llmChatMessage = await sendMessageToApi(
          updatedMessages,
          baseUrl,
          username,
          setIsLoading
        );
        setMessages((prev: ChatMessage[]) => [...prev, llmChatMessage]);
      } catch (err) {
        console.error(
          err instanceof Error
            ? err.message
            : "An error occurred while sending the message"
        );
      }
    },
    [userInput, messages, sessionId, baseUrl, username, setMessages]
  );

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      e.currentTarget.form?.requestSubmit();
    }
  };

  const handleQuestionClick = React.useCallback(
    async (question: string) => {
      const userMessage: UserMessage = {
        content: question,
        wasRecommended: true,
      };

      const userChatMessage: ChatMessage = {
        userMessage,
        sessionId,
        messageId: uuidv4(),
        asOfTimestamp: new Date(),
      };

      setMessages((prev: ChatMessage[]) => [...prev, userChatMessage]);

      try {
        const updatedMessages = [...messages, userChatMessage];
        const llmChatMessage = await sendMessageToApi(
          updatedMessages,
          baseUrl,
          username,
          setIsLoading
        );
        setFollowUpQuestions(llmChatMessage.llmMessage?.followUpQuestions || []);
        setMessages((prev: ChatMessage[]) => [...prev, llmChatMessage]);
      } catch (err) {
        console.error(
          err instanceof Error
            ? err.message
            : "An error occurred while sending the message"
        );
      }
    },
    [sessionId, messages, baseUrl, username, setMessages]
  );

  const handleClearChat = React.useCallback(() => {
    setMessages([]);
    setSessionId(uuidv4());
  }, [setMessages, setSessionId]);

  return (
    <div className="chat-layout">
      <div className="left-column">
        <HotTopics topics={topics} onTopicClick={handleQuestionClick} />
        <ClickableQuestions header="Starter Questions" questions={questions} onQuestionClick={handleQuestionClick} />
        {followUpQuestions.length > 0 && (
          <ClickableQuestions header="Follow Up Questions" questions={followUpQuestions} onQuestionClick={handleQuestionClick} />
        )}
        <div className="input-container">
          <form onSubmit={handleSubmit} className="input-form">
            <button
              type="button"
              onClick={handleClearChat}
              className="action-button clear-button"
              disabled={isLoading || messages.length === 0}
            >
              <Trash2 size={20} />
            </button>
            <textarea
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder="Ask a question..."
              className="input-textarea"
              disabled={isLoading}
              rows={3}
            />
            <button
              type="submit"
              className="action-button send-button"
              disabled={isLoading || userInput.trim().length === 0}
            >
              <Send size={20} />
            </button>
          </form>
        </div>
      </div>

      <div className="right-column">
        {messages.map((message) => (
          <ChatMessageDisplay key={message.messageId} chatMessage={message} />
        ))}
        {isLoading && (
          <div className="message assistant-message loading-message">
            <div className="message-content">
              <ThinkingChat />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Chat;