import React from "react";
import "../styles/HotTopics.css";

interface HotTopicsProps {
  topics: string[];
  onTopicClick: (topic: string) => void;
}

const MAX_CHAR = 20;

const HotTopics: React.FC<HotTopicsProps> = ({ topics, onTopicClick }) => {
  return (
    <div className="hot-topics-container">
      <h2 className="hot-topics-title">🔥 Hot Topics</h2>
      <div className="hot-topics-buttons">
        {topics.map((topic) => (
          <button
            key={topic}
            onClick={() => onTopicClick(topic)}
            className="hot-topic-button"
            title={topic}
          >
            {topic.length > MAX_CHAR ? `${topic.slice(0, MAX_CHAR)}...` : topic}
          </button>
        ))}
      </div>
    </div>
  );
};

export default HotTopics;
