import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { BaseUrlContext } from "../Contexts";

interface AuthContextType {
  isAuthenticated: boolean;
  role: string;
  username: string;
  login: (username: string, role: string) => void;
  oauthLogin: (provider: string, username: string, role: string) => void;
  emailLogin: (email: string, password: string) => Promise<void>;
  checkEmailAllowed: (email: string) => Promise<void>;
  logout: () => void;
  isAllowed: boolean | null;
  loading: boolean;
  error: string | null;
}

interface AuthState {
  isAuthenticated: boolean;
  role: string;
  username: string;
  token?: string;
}

const AUTH_STORAGE_KEY = "auth_state";
const TOKEN_EXPIRY_KEY = "token_expiry";

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

interface AuthProviderProps {
  children: ReactNode;
  oauthClientId: string;
}

const getApiUrl = (baseUrl: string, userEmail: string) => {
  return `${baseUrl}/users/${userEmail}/login`;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({
  children,
  oauthClientId,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [role, setRole] = useState("User");
  const [username, setUsername] = useState("");
  const [isAllowed, setIsAllowed] = useState<boolean | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const baseUrl = useContext(BaseUrlContext);

  // Load cached auth state on mount
  useEffect(() => {
    const loadAuthState = () => {
      const cachedState = localStorage.getItem(AUTH_STORAGE_KEY);
      const tokenExpiry = localStorage.getItem(TOKEN_EXPIRY_KEY);

      if (cachedState && tokenExpiry) {
        const state: AuthState = JSON.parse(cachedState);
        const expiryTime = parseInt(tokenExpiry, 10);

        // Check if token is still valid (not expired)
        if (expiryTime > Date.now()) {
          setIsAuthenticated(state.isAuthenticated);
          setRole(state.role);
          setUsername(state.username);
        } else {
          // Clear expired auth state
          localStorage.removeItem(AUTH_STORAGE_KEY);
          localStorage.removeItem(TOKEN_EXPIRY_KEY);
        }
      }
      setLoading(false);
    };

    loadAuthState();
  }, []);

  const saveAuthState = (state: AuthState) => {
    // Set token expiry to 24 hours from now
    const expiryTime = Date.now() + 24 * 60 * 60 * 1000;
    localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(state));
    localStorage.setItem(TOKEN_EXPIRY_KEY, expiryTime.toString());
  };

  const login = (username: string, role: string) => {
    const newState = { isAuthenticated: true, username, role };
    setIsAuthenticated(true);
    setUsername(username);
    setRole(role);
    saveAuthState(newState);
  };

  const oauthLogin = (provider: string, username: string, role: string) => {
    const newState = { isAuthenticated: true, username, role };
    setIsAuthenticated(true);
    setUsername(username);
    setRole(role);
    saveAuthState(newState);
  };

  const emailLogin = async (email: string, password: string) => {
    setLoading(true);
    setError(null);
    const apiUrl = getApiUrl(baseUrl, email);

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ password }),
      });

      if (!response.ok) {
        throw new Error("Invalid credentials");
      }

      const data = await response.json();
      const newState = {
        isAuthenticated: true,
        username: email,
        role: data.role || "User",
        token: data.token, // If your API returns a token
      };

      setIsAuthenticated(true);
      setUsername(email);
      setRole(data.role || "User");
      saveAuthState(newState);
    } catch (error) {
      setError("Email login failed. Please check your credentials.");
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const logout = () => {
    setIsAuthenticated(false);
    setUsername("");
    setRole("User");
    localStorage.removeItem(AUTH_STORAGE_KEY);
    localStorage.removeItem(TOKEN_EXPIRY_KEY);
  };

  const checkEmailAllowed = async (email: string): Promise<void> => {
    setLoading(true);
    setError(null);

    const apiUrl = getApiUrl(baseUrl, email);
    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to verify email");
      }

      const data = await response.json();

      if (data.allowed) {
        setIsAllowed(true);
      } else {
        setIsAllowed(false);
        setError("Email is not allowed");
      }
    } catch (err) {
      setError("An error occurred while checking the email.");
      setIsAllowed(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        role,
        username,
        login,
        oauthLogin,
        emailLogin,
        checkEmailAllowed,
        logout,
        isAllowed,
        loading,
        error,
      }}
    >
      <GoogleOAuthProvider clientId={oauthClientId}>
        {children}
      </GoogleOAuthProvider>
    </AuthContext.Provider>
  );
};
