// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.0
//   protoc               v3.20.3
// source: protos/pdf.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { Timestamp } from "../google/protobuf/timestamp";

export const protobufPackage = "finllm";

export enum ProcessingStatus {
  PENDING = 0,
  PROCESSING = 1,
  COMPLETED = 2,
  ERROR = 3,
  KILLED = 4,
  UNRECOGNIZED = -1,
}

export function processingStatusFromJSON(object: any): ProcessingStatus {
  switch (object) {
    case 0:
    case "PENDING":
      return ProcessingStatus.PENDING;
    case 1:
    case "PROCESSING":
      return ProcessingStatus.PROCESSING;
    case 2:
    case "COMPLETED":
      return ProcessingStatus.COMPLETED;
    case 3:
    case "ERROR":
      return ProcessingStatus.ERROR;
    case 4:
    case "KILLED":
      return ProcessingStatus.KILLED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ProcessingStatus.UNRECOGNIZED;
  }
}

export function processingStatusToJSON(object: ProcessingStatus): string {
  switch (object) {
    case ProcessingStatus.PENDING:
      return "PENDING";
    case ProcessingStatus.PROCESSING:
      return "PROCESSING";
    case ProcessingStatus.COMPLETED:
      return "COMPLETED";
    case ProcessingStatus.ERROR:
      return "ERROR";
    case ProcessingStatus.KILLED:
      return "KILLED";
    case ProcessingStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface PdfFile {
  pdfName: string;
  pdfBytes: Uint8Array;
  userEmail: string;
}

export interface PdfMetadata {
  userEmail: string;
  gcsUri: string;
  processingStatus: ProcessingStatus;
  uploadTimestamp: Date | undefined;
  summary: string;
  keyThemes: string[];
  pdfExtractionId: string;
}

export interface PdfMetadataList {
  pdfMetadata: PdfMetadata[];
}

function createBasePdfFile(): PdfFile {
  return { pdfName: "", pdfBytes: new Uint8Array(0), userEmail: "" };
}

export const PdfFile: MessageFns<PdfFile> = {
  encode(message: PdfFile, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.pdfName !== "") {
      writer.uint32(10).string(message.pdfName);
    }
    if (message.pdfBytes.length !== 0) {
      writer.uint32(18).bytes(message.pdfBytes);
    }
    if (message.userEmail !== "") {
      writer.uint32(26).string(message.userEmail);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PdfFile {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePdfFile();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pdfName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pdfBytes = reader.bytes();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.userEmail = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PdfFile {
    return {
      pdfName: isSet(object.pdfName) ? globalThis.String(object.pdfName) : "",
      pdfBytes: isSet(object.pdfBytes) ? bytesFromBase64(object.pdfBytes) : new Uint8Array(0),
      userEmail: isSet(object.userEmail) ? globalThis.String(object.userEmail) : "",
    };
  },

  toJSON(message: PdfFile): unknown {
    const obj: any = {};
    if (message.pdfName !== "") {
      obj.pdfName = message.pdfName;
    }
    if (message.pdfBytes.length !== 0) {
      obj.pdfBytes = base64FromBytes(message.pdfBytes);
    }
    if (message.userEmail !== "") {
      obj.userEmail = message.userEmail;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PdfFile>, I>>(base?: I): PdfFile {
    return PdfFile.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PdfFile>, I>>(object: I): PdfFile {
    const message = createBasePdfFile();
    message.pdfName = object.pdfName ?? "";
    message.pdfBytes = object.pdfBytes ?? new Uint8Array(0);
    message.userEmail = object.userEmail ?? "";
    return message;
  },
};

function createBasePdfMetadata(): PdfMetadata {
  return {
    userEmail: "",
    gcsUri: "",
    processingStatus: 0,
    uploadTimestamp: undefined,
    summary: "",
    keyThemes: [],
    pdfExtractionId: "",
  };
}

export const PdfMetadata: MessageFns<PdfMetadata> = {
  encode(message: PdfMetadata, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.userEmail !== "") {
      writer.uint32(10).string(message.userEmail);
    }
    if (message.gcsUri !== "") {
      writer.uint32(18).string(message.gcsUri);
    }
    if (message.processingStatus !== 0) {
      writer.uint32(24).int32(message.processingStatus);
    }
    if (message.uploadTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.uploadTimestamp), writer.uint32(34).fork()).join();
    }
    if (message.summary !== "") {
      writer.uint32(42).string(message.summary);
    }
    for (const v of message.keyThemes) {
      writer.uint32(50).string(v!);
    }
    if (message.pdfExtractionId !== "") {
      writer.uint32(58).string(message.pdfExtractionId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PdfMetadata {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePdfMetadata();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userEmail = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.gcsUri = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.processingStatus = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.uploadTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.summary = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.keyThemes.push(reader.string());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.pdfExtractionId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PdfMetadata {
    return {
      userEmail: isSet(object.userEmail) ? globalThis.String(object.userEmail) : "",
      gcsUri: isSet(object.gcsUri) ? globalThis.String(object.gcsUri) : "",
      processingStatus: isSet(object.processingStatus) ? processingStatusFromJSON(object.processingStatus) : 0,
      uploadTimestamp: isSet(object.uploadTimestamp) ? fromJsonTimestamp(object.uploadTimestamp) : undefined,
      summary: isSet(object.summary) ? globalThis.String(object.summary) : "",
      keyThemes: globalThis.Array.isArray(object?.keyThemes)
        ? object.keyThemes.map((e: any) => globalThis.String(e))
        : [],
      pdfExtractionId: isSet(object.pdfExtractionId) ? globalThis.String(object.pdfExtractionId) : "",
    };
  },

  toJSON(message: PdfMetadata): unknown {
    const obj: any = {};
    if (message.userEmail !== "") {
      obj.userEmail = message.userEmail;
    }
    if (message.gcsUri !== "") {
      obj.gcsUri = message.gcsUri;
    }
    if (message.processingStatus !== 0) {
      obj.processingStatus = processingStatusToJSON(message.processingStatus);
    }
    if (message.uploadTimestamp !== undefined) {
      obj.uploadTimestamp = message.uploadTimestamp.toISOString();
    }
    if (message.summary !== "") {
      obj.summary = message.summary;
    }
    if (message.keyThemes?.length) {
      obj.keyThemes = message.keyThemes;
    }
    if (message.pdfExtractionId !== "") {
      obj.pdfExtractionId = message.pdfExtractionId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PdfMetadata>, I>>(base?: I): PdfMetadata {
    return PdfMetadata.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PdfMetadata>, I>>(object: I): PdfMetadata {
    const message = createBasePdfMetadata();
    message.userEmail = object.userEmail ?? "";
    message.gcsUri = object.gcsUri ?? "";
    message.processingStatus = object.processingStatus ?? 0;
    message.uploadTimestamp = object.uploadTimestamp ?? undefined;
    message.summary = object.summary ?? "";
    message.keyThemes = object.keyThemes?.map((e) => e) || [];
    message.pdfExtractionId = object.pdfExtractionId ?? "";
    return message;
  },
};

function createBasePdfMetadataList(): PdfMetadataList {
  return { pdfMetadata: [] };
}

export const PdfMetadataList: MessageFns<PdfMetadataList> = {
  encode(message: PdfMetadataList, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.pdfMetadata) {
      PdfMetadata.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PdfMetadataList {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePdfMetadataList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pdfMetadata.push(PdfMetadata.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PdfMetadataList {
    return {
      pdfMetadata: globalThis.Array.isArray(object?.pdfMetadata)
        ? object.pdfMetadata.map((e: any) => PdfMetadata.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PdfMetadataList): unknown {
    const obj: any = {};
    if (message.pdfMetadata?.length) {
      obj.pdfMetadata = message.pdfMetadata.map((e) => PdfMetadata.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PdfMetadataList>, I>>(base?: I): PdfMetadataList {
    return PdfMetadataList.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PdfMetadataList>, I>>(object: I): PdfMetadataList {
    const message = createBasePdfMetadataList();
    message.pdfMetadata = object.pdfMetadata?.map((e) => PdfMetadata.fromPartial(e)) || [];
    return message;
  },
};

function bytesFromBase64(b64: string): Uint8Array {
  if ((globalThis as any).Buffer) {
    return Uint8Array.from(globalThis.Buffer.from(b64, "base64"));
  } else {
    const bin = globalThis.atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
      arr[i] = bin.charCodeAt(i);
    }
    return arr;
  }
}

function base64FromBytes(arr: Uint8Array): string {
  if ((globalThis as any).Buffer) {
    return globalThis.Buffer.from(arr).toString("base64");
  } else {
    const bin: string[] = [];
    arr.forEach((byte) => {
      bin.push(globalThis.String.fromCharCode(byte));
    });
    return globalThis.btoa(bin.join(""));
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
