import React, { useState } from "react";
import "../styles/PdfStatusList.css";
import { PdfMetadata, ProcessingStatus } from "../generated/protos/pdf";
import { Clock, Loader, CheckCircle, AlertCircle } from "lucide-react";
import useFileApi from "../hooks/useFileApi";

interface PdfStatusListProps {
  pdfStatusList: PdfMetadata[] | null;
}

const PdfStatusList: React.FC<PdfStatusListProps> = ({ pdfStatusList }) => {
  const { handleKillJob } = useFileApi();
  const [hoveredPdf, setHoveredPdf] = useState<string | null>(null);

  const renderStatusIcon = (status: ProcessingStatus) => {
    switch (status) {
      case ProcessingStatus.PENDING:
        return <Clock className="status-icon pending" size={16} />;
      case ProcessingStatus.PROCESSING:
        return <Loader className="status-icon spinning" size={16} />;
      case ProcessingStatus.COMPLETED:
        return <CheckCircle className="status-icon completed" size={16} />;
      case ProcessingStatus.ERROR:
        return <AlertCircle className="status-icon error" size={16} />;
      default:
        return null;
    }
  };

  const sortedPdfStatusList = pdfStatusList
    ? [...pdfStatusList].sort((a, b) => {
        const nameA = a.gcsUri.split("/").pop()?.toLowerCase() || "";
        const nameB = b.gcsUri.split("/").pop()?.toLowerCase() || "";
        return nameA.localeCompare(nameB);
      })
    : [];

  return (
    <div className="pdf-status-list">
      <ul className="pdf-list">
        {sortedPdfStatusList.map((pdf, index) => (
          <li
            key={index}
            className="pdf-status-item"
            onMouseEnter={() => setHoveredPdf(pdf.gcsUri)}
            onMouseLeave={() => setHoveredPdf(null)}
          >
            <div className="pdf-info">
              <span className="pdf-name">{pdf.gcsUri.split("/").pop()}</span>
              <div className="pdf-status-container">
                <div className="pdf-status-summary">
                  {renderStatusIcon(pdf.processingStatus)}
                  <span
                    className={`pdf-status ${ProcessingStatus[
                      pdf.processingStatus
                    ].toLowerCase()}`}
                  >
                    {ProcessingStatus[pdf.processingStatus]}
                  </span>
                </div>
                <button
                  className="kill-job-x-btn"
                  onClick={() => handleKillJob(pdf)}
                  title="Kill Job"
                >
                  ✕
                </button>
              </div>
              {hoveredPdf === pdf.gcsUri && pdf.summary && (
                <div className="summary-popup">
                  <p className="summary-popup-text">
                    {pdf.summary || "No summary available"}
                  </p>
                </div>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PdfStatusList;
