import { Github, Linkedin, Twitter } from "lucide-react";
import "../styles/Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <p className="footer-text">
          &copy; 2024 Metis LLM. All rights reserved.
        </p>
        <div className="footer-icons">
          <a
            href="https://github.com/your-link"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-icon"
          >
            <Github size={24} />
          </a>
          <a
            href="https://www.linkedin.com/your-link"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-icon"
          >
            <Linkedin size={24} />
          </a>
          <a
            href="https://twitter.com/your-link"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-icon"
          >
            <Twitter size={24} />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;