import { useState, useEffect } from "react";

const getApiUrl = (baseUrl: string, userEmail: string) => {
  return `${baseUrl}/users/${userEmail}/graph`;
};

const useFetchGraphHtml = (baseUrl: string, userEmail: string) => {
  const [htmlContent, setHtmlContent] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const url = getApiUrl(baseUrl, userEmail);

    const fetchHtml = async () => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Error fetching graph: ${response.statusText}`);
        }
        const html = await response.text();
        setHtmlContent(html);
      } catch (err) {
        setError((err as Error).message);
      } finally {
        setLoading(false);
      }
    };

    fetchHtml();
  }, [baseUrl, userEmail]);

  return { htmlContent, loading, error };
};

export default useFetchGraphHtml;
