import { useEffect, useState } from "react";

const CACHE_KEY_PREFIX = "pdfCache-";
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

const useFetchPdfByCitationID = (
  baseUrl: string,
  userEmail: string,
  citationId: string | null
) => {
  const [pdfBlobUrl, setPdfBlobUrl] = useState<string | null>(null);
  const [pageNumber, setPageNumber] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPdf = async () => {
      if (!citationId || !userEmail) return;
      const cacheKey = `${CACHE_KEY_PREFIX}${userEmail}-${citationId}`;
      const cachedData = localStorage.getItem(cacheKey);
      if (cachedData) {
        const { timestamp, data } = JSON.parse(cachedData);
        const now = Date.now();
        if (now - timestamp < CACHE_DURATION) {
          setPdfBlobUrl(data.pdfBlobUrl);
          setPageNumber(data.pageNumber);
          setLoading(false);
          return;
        }
      }
      try {
        const response = await fetch(
          `${baseUrl}/users/${userEmail}/chat/${citationId}`,
          {
            method: "GET",
            headers: {
              Accept: "application/octet-stream",
            },
            credentials: "include",
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const pdfBlob = await response.blob();
        const blobUrl = URL.createObjectURL(pdfBlob);
        const pageNumberFromHeader = response.headers.get("X-Page-Number");
        const parsedPageNumber = pageNumberFromHeader
          ? parseInt(pageNumberFromHeader, 10)
          : null;
        const dataToCache = {
          pdfBlobUrl: blobUrl,
          pageNumber: parsedPageNumber || 1,
        };
        localStorage.setItem(
          cacheKey,
          JSON.stringify({ timestamp: Date.now(), data: dataToCache })
        );

        setPdfBlobUrl(blobUrl);
        setPageNumber(parsedPageNumber || 1);
      } catch (error) {
        console.error(error);
        setError(error instanceof Error ? error.message : String(error));
      } finally {
        setLoading(false);
      }
    };

    fetchPdf();
  }, [baseUrl, userEmail, citationId]);

  return { pdfBlobUrl, pageNumber, loading, error };
};

export default useFetchPdfByCitationID;
