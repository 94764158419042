import React, { useContext, useState, useEffect } from "react";
import useMetricTableApi from "../hooks/useMetricTableApi";
import { useAuth } from "../context/AuthContext";
import { BaseUrlContext } from "../Contexts";
import Table from "./Table";
import "../styles/TableRelationship.css";

const CACHE_KEY = "metricTablesCache";
const CACHE_EXPIRY_TIME = 1000 * 10 * 60; // 10 minutes

const TableRelationship: React.FC = () => {
  const userEmail: string = useAuth().username;
  const baseUrl = useContext(BaseUrlContext);
  const [refreshKey, setRefreshKey] = useState(0);
  const [loading, setLoading] = useState(false);
  const [cachedData, setCachedData] = useState<any>(null);

  const {
    metricTables,
    loading: dataLoading,
    error,
  } = useMetricTableApi(baseUrl, userEmail, refreshKey);

  const getCachedMetrics = () => {
    const cachedItem = localStorage.getItem(CACHE_KEY);
    if (!cachedItem) return null;

    const parsedCache = JSON.parse(cachedItem);
    const currentTime = new Date().getTime();

    if (currentTime - parsedCache.timestamp > CACHE_EXPIRY_TIME) {
      localStorage.removeItem(CACHE_KEY);
      return null;
    }

    return parsedCache.data;
  };

  const saveToCache = (data: any) => {
    const cacheData = {
      data,
      timestamp: new Date().getTime(),
    };
    localStorage.setItem(CACHE_KEY, JSON.stringify(cacheData));
  };

  useEffect(() => {
    const cachedMetrics = getCachedMetrics();
    if (cachedMetrics) {
      setCachedData(cachedMetrics);
    } else if (!dataLoading && metricTables) {
      saveToCache(metricTables);
      setCachedData(metricTables);
    }
  }, [metricTables, dataLoading]);

  const getColumns = (data: Array<Record<string, string>>) => {
    if (data.length > 0) {
      const columns = Object.keys(data[0]);
      return [...columns];
    }
    return [];
  };

  const combineMetricData = () => {
    if (!cachedData) return [];
    return Object.keys(cachedData).reduce((combined, metric) => {
      const metricData = cachedData[metric].map((entry: any) => ({
        metric,
        ...entry,
      }));
      return combined.concat(metricData);
    }, [] as Array<Record<string, string>>);
  };

  const combinedData = combineMetricData();
  const columns = getColumns(combinedData);

  if (dataLoading && !cachedData) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
        <p>Loading data, please wait...</p>
      </div>
    );
  }

  if (error) {
    return <p>Error loading data</p>;
  }

  return (
    <div>
      {combinedData.length > 0 && (
        <Table
          custom_css="relationship_table"
          data={combinedData}
          columns={[...columns]}
          onRowClick={() => {}}
          filterDate={""}
          isFilterEnabled={false}
          monthsAhead={0}
        />
      )}
    </div>
  );
};

export default TableRelationship;
