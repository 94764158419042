import { useContext, useState } from "react";
import { PdfFile, PdfMetadataList, PdfMetadata } from "../generated/protos/pdf";
import { useAuth } from "../context/AuthContext";
import { BaseUrlContext } from "../Contexts";

const getApiUrl = (baseUrl: string, userEmail: string) => {
  return `${baseUrl}/users/${userEmail}/pdfs`;
};

const killFileUrl = (baseUrl: string, userEmail: string) => {
  return `${baseUrl}/users/${userEmail}/pdfs/kill`;
};

export default function useFileApi() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [response, setResponse] = useState<string>("");
  const [pdfStatus] = useState<PdfMetadata[]>([]);
  const { username } = useAuth();
  const baseUrl = useContext(BaseUrlContext);

  const uploadFile = async (fileName: string, binaryData: Uint8Array) => {
    setIsLoading(true);
    setError(null);

    try {
      const message = PdfFile.create({
        pdfName: fileName,
        pdfBytes: binaryData,
        userEmail: username,
      });

      const encodedMessage = PdfFile.encode(message).finish();

      const apiUrl = getApiUrl(baseUrl, username);

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/octet-stream",
        },
        body: encodedMessage,
      });
      if (!response.ok) {
        throw new Error(`File upload failed with status ${response.status}`);
      }
      const result = await response.json();
      setResponse(result.message);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setError("An error occurred while uploading the file");
      setIsLoading(false);
    }
  };

  const fetchPdfStatus = async (): Promise<PdfMetadataList | null> => {
    try {
      const statusUrl = getApiUrl(baseUrl, username);

      const response = await fetch(statusUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/octet-stream",
        },
      });

      if (!response.ok) {
        throw new Error(
          `Failed to fetch PDF status with status ${response.status}`
        );
      }

      const responseData = await response.arrayBuffer();
      const decodedData: PdfMetadataList = PdfMetadataList.decode(
        new Uint8Array(responseData)
      );
      return decodedData;
    } catch (err) {
      console.error(err);
      setError("An error occurred while fetching the PDF status");
      return null;
    }
  };

  const handleKillJob = async (pdf: PdfMetadata) => {
    setIsLoading(true);
    setError(null);

    try {
      const encodedMessage = PdfMetadata.encode(pdf).finish();
      const apiUrl = killFileUrl(baseUrl, username);

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/octet-stream",
        },
        body: encodedMessage,
      });

      if (!response.ok) {
        throw new Error(`Failed to kill job with status ${response.status}`);
      }
    } catch (error) {
      console.error("Error killing the job:", error);
      setError(`Failed to kill job for ${pdf.gcsUri.split("/").pop()}.`);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    uploadFile,
    fetchPdfStatus,
    handleKillJob,
    isLoading,
    error,
    response,
    pdfStatus,
  };
}
