import { useEffect, useState, useCallback } from "react";

interface TableEntry {
  pdf_name: string;
  link: string;
}

interface KeyThemes {
  [theme: string]: TableEntry[];
}

const getApiUrl = (baseUrl: string, userEmail: string) => {
  return `${baseUrl}/users/${userEmail}/key-themes`;
};

const getPDFUrl = (baseUrl: string, userEmail: string, pdfName: string) => {
  return `${baseUrl}/users/${userEmail}/key-themes/${pdfName}`;
};

const useKeyThemesApi = (baseUrl: string, userEmail: string) => {
  const [keyThemes, setKeyThemes] = useState<KeyThemes>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [fetchingPdf, setFetchingPdf] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = getApiUrl(baseUrl, userEmail);
        const response = await fetch(url);
        if (!response.ok) {
          const errorText = await response.text();
          console.error("Error response:", errorText);
          throw new Error(
            `HTTP error! status: ${response.status}, message: ${errorText}`
          );
        }
        const rawData: KeyThemes = await response.json();
        setKeyThemes(rawData);
      } catch (error) {
        console.error("Error fetching key themes:", error);
        setError(error instanceof Error ? error.message : String(error));
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [baseUrl, userEmail]);

  const fetchPdf = useCallback(
    async (pdfName: string) => {
      setFetchingPdf(pdfName);
      try {
        const url = getPDFUrl(baseUrl, userEmail, pdfName);
        const response = await fetch(url);
        if (!response.ok) {
          const errorText = await response.text();
          console.error("Error response:", errorText);
          throw new Error(
            `HTTP error! status: ${response.status}, message: ${errorText}`
          );
        }
        const blob = await response.blob();
        const pdfUrl = window.URL.createObjectURL(blob);
        window.open(pdfUrl, "_blank");
      } catch (error) {
        console.error("Error fetching PDF:", error);
        setError(error instanceof Error ? error.message : String(error));
      } finally {
        setFetchingPdf(null);
      }
    },
    [baseUrl, userEmail]
  );

  return { keyThemes, loading, error, fetchPdf, fetchingPdf };
};

export default useKeyThemesApi;
