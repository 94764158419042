import React, { useContext, useEffect } from "react";
import useFetchGraphHtml from "../hooks/useGraphApi";
import { useAuth } from "../context/AuthContext";
import { BaseUrlContext } from "../Contexts";

const CACHE_KEY = "knowledgeGraphHtml";
const CACHE_EXPIRY_TIME = 1000 * 10 * 60; // 10 minutes

const KnowledgeGraph: React.FC = () => {
  const baseUrl = useContext(BaseUrlContext);
  const userEmail = useAuth().username;
  const { htmlContent, loading, error } = useFetchGraphHtml(baseUrl, userEmail);
  const [cachedHtml, setCachedHtml] = React.useState<string | null>(null);

  useEffect(() => {
    const cachedData = localStorage.getItem(CACHE_KEY);
    if (cachedData) {
      const { html, timestamp } = JSON.parse(cachedData);
      const currentTime = new Date().getTime();
      if (currentTime - timestamp < CACHE_EXPIRY_TIME) {
        setCachedHtml(html);
      } else {
        localStorage.removeItem(CACHE_KEY);
      }
    }
  }, []);

  useEffect(() => {
    if (htmlContent && !cachedHtml) {
      const cacheData = {
        html: htmlContent,
        timestamp: new Date().getTime(),
      };
      localStorage.setItem(CACHE_KEY, JSON.stringify(cacheData));
      setCachedHtml(htmlContent);
    }
  }, [htmlContent]);

  if (loading && !cachedHtml) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
        <p>Loading graph, please wait...</p>
      </div>
    );
  }

  return (
    <div style={{ width: "100%", height: "100vh" }}>
      {error && <p>Error loading graph: {error}</p>}
      {cachedHtml ? (
        <iframe
          srcDoc={cachedHtml}
          title="Knowledge Graph"
          style={{
            width: "100%",
            height: "100%",
            border: "none",
            display: "block",
          }}
        />
      ) : (
        htmlContent && (
          <iframe
            srcDoc={htmlContent}
            title="Knowledge Graph"
            style={{
              width: "100%",
              height: "100%",
              border: "none",
              display: "block",
            }}
          />
        )
      )}
    </div>
  );
};

export default KnowledgeGraph;
