import React from "react";
import "../styles/ClickableQuestions.css";

interface ClickableQuestionsProps {
  header: string;
  questions: string[];
  onQuestionClick: (topic: string) => void;
}

const ClickableQuestions: React.FC<ClickableQuestionsProps> = ({ header, questions, onQuestionClick }) => {
  return (
    <div className="questions-container">
    <h2 className="questions-title">{header}</h2>
    {questions.map((question) => (
      <button
        key={question}
        onClick={() => onQuestionClick(question)}
        className="question-button"
      >
        {question}
      </button>
    ))}
  </div>
  );
};

export default ClickableQuestions;
