import React, { useState, useMemo, useContext, useEffect } from "react";
import { ChevronDown, ChevronUp, User, Bot } from "lucide-react";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { ChatMessage } from "../../generated/protos/chat";
import "../../styles/ChatMessageDisplay.css";
import useFetchPdfByCitationID from "../../hooks/useFetchPdfByCitationID";
import { BaseUrlContext } from "../../Contexts";
import { useAuth } from "../../context/AuthContext";

interface ChatMessageDisplayProps {
  chatMessage: ChatMessage;
}

const mapEvidenceToCitations = (supportPoints: any[]): Map<string, number> => {
  const citationMap = new Map<string, number>();
  let citationCounter = 0;
  supportPoints.forEach((supportPoint) => {
    supportPoint.evidenceIds.forEach((id: string) => {
      if (!citationMap.has(id)) {
        citationMap.set(id, citationCounter++);
      }
    });
  });
  return citationMap;
};

const ChatMessageDisplay: React.FC<ChatMessageDisplayProps> = ({
  chatMessage,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [selectedCitationId, setSelectedCitationId] = useState<string | null>(
    null
  );
  const userEmail: string = useAuth().username;
  const baseUrl: string = useContext(BaseUrlContext);

  const { pdfBlobUrl, pageNumber, loading, error } = useFetchPdfByCitationID(
    baseUrl,
    userEmail,
    selectedCitationId
  );

  const citationMap = useMemo(() => {
    if (
      chatMessage.llmMessage &&
      Array.isArray(chatMessage.llmMessage.supportPoints)
    ) {
      return mapEvidenceToCitations(chatMessage.llmMessage.supportPoints);
    }
    return new Map<string, number>();
  }, [chatMessage]);

  const toggleCollapse = () => {
    setIsCollapsed((prev) => !prev);
  };

  const handleCitationClick = (id: string) => {
    console.log("Citation clicked", id);
    setSelectedCitationId(id);
  };

  useEffect(() => {
    if (pdfBlobUrl && pageNumber !== null) {
      const pdfUrlWithPage = `${pdfBlobUrl}#page=${pageNumber}`;
      const pdfWindow = window.open();
      if (pdfWindow) {
        pdfWindow.location.href = pdfUrlWithPage;
        pdfWindow.focus();
      }
    }
  }, [pdfBlobUrl, pageNumber]);

  return (
    <div>
      {chatMessage?.userMessage?.content && (
        <div className="message user-message">
          <div className="message-icon">
            <User size={20} />
          </div>
          <div className="message-content">
            <ReactMarkdown remarkPlugins={[remarkGfm]}>
              {chatMessage.userMessage.content}
            </ReactMarkdown>
          </div>
        </div>
      )}

      {chatMessage?.llmMessage && (
        <div className="message assistant-message">
          <div className="message-icon">
            <Bot size={20} />
          </div>
          <div className="message-content">
            <ReactMarkdown remarkPlugins={[remarkGfm]}>
              {chatMessage.llmMessage.response || "No response available"}
            </ReactMarkdown>

            <button
              className={`toggle-button ${
                isCollapsed ? "collapsed" : "expanded"
              }`}
              onClick={toggleCollapse}
            >
              {isCollapsed
                ? "Show Supporting Evidence"
                : "Hide Supporting Evidence"}
              <span className="toggle-icon">
                {isCollapsed ? (
                  <ChevronDown size={16} />
                ) : (
                  <ChevronUp size={16} />
                )}
              </span>
            </button> 
            {!isCollapsed &&
              Array.isArray(chatMessage.llmMessage.supportPoints) && (
                <div className="support-container">
                  {chatMessage.llmMessage.supportPoints.map(
                    (supportPoint, index) => (
                      <div className="blob-container" key={index}>
                        <div className="evidence-text">
                          <ReactMarkdown remarkPlugins={[remarkGfm]}>
                            {supportPoint.text || "No supporting text available"}
                          </ReactMarkdown>
                        </div>
                        <div className="citations-container">
                          {Array.isArray(supportPoint.evidenceIds)
                            ? supportPoint.evidenceIds.map(
                                (id: string, idIndex: number) => (
                                  <span
                                    key={idIndex}
                                    className="citation-blob"
                                    onClick={() => handleCitationClick(id)}
                                  >
                                    {citationMap.get(id)}
                                  </span>
                                )
                              )
                            : "No citations available"}
                        </div>
                      </div>
                    )
                  )}
                </div>
              )}
            {selectedCitationId && (
              <div className="evidence-detail">
                {loading && <p></p>}
                {error && <p></p>}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatMessageDisplay;