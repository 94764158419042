import React, { createContext, useState, useContext } from 'react';
import { ChatMessage } from '../generated/protos/chat';
import { Dispatch, SetStateAction } from 'react';

interface ChatContextType {
  messages: ChatMessage[];
  setMessages: Dispatch<SetStateAction<ChatMessage[]>>;
  sessionId: string;
  setSessionId: Dispatch<SetStateAction<string>>;
}

export const ChatContext = createContext<ChatContextType | undefined>(undefined);

export const ChatProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [sessionId, setSessionId] = useState<string>(() => crypto.randomUUID());

  return (
    <ChatContext.Provider value={{ messages, setMessages, sessionId, setSessionId }}>
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error('useChat must be used within a ChatProvider');
  }
  return context;
};