import React from "react";
import "../../styles/ThinkingChat.css";

const ThinkingChat = () => {
  return (
    <div className="thinking-container">
      <div className="thinking-dots">
        <div className="thinking-dot"></div>
        <div className="thinking-dot"></div>
        <div className="thinking-dot"></div>
      </div>
    </div>
  );
};

export default ThinkingChat;
