import React from "react";
import Header from "./components/Header";
import Home from "./components/Home";
import Footer from "./components/Footer";
import { BaseUrlContext } from "./Contexts";
import LoginPage from "./components/LoginPage";
import { AuthProvider, useAuth } from "./context/AuthContext";
import "./App.css";

const App: React.FC = () => {
  const baseUrl = process.env.REACT_APP_BASE_API_URL;
  if (!baseUrl) {
    throw new Error("REACT_APP_BASE_API_URL is not set");
  }
  const oauthClientId = process.env.REACT_APP_OAUTH_CLIENT_ID;
  if (!oauthClientId) {
    throw new Error("REACT_APP_OAUTH_CLIENT_ID is not set");
  }

  return (
    <BaseUrlContext.Provider value={baseUrl}>
      <AuthProvider oauthClientId={oauthClientId}>
        <AppContent />
      </AuthProvider>
    </BaseUrlContext.Provider>
  );
};

const Dashboard: React.FC = () => {
  return (
    <>
      <Header />
      <div className="main-content">
        <Home />
      </div>
      <Footer />
    </>
  );
};

const AppContent: React.FC = () => {
  const { isAuthenticated } = useAuth();

  return (
    <div className="App">{isAuthenticated ? <Dashboard /> : <LoginPage />}</div>
  );
};

export default App;
