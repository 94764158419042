import React from "react";
import "../styles/Header.css";
import { useAuth } from "../context/AuthContext";
import { LogOut } from "lucide-react";

const Header: React.FC = () => {
  const { role, username, logout } = useAuth();

  const handleLogout = () => {
    logout();
  };

  return (
    <header className="header">
      <div className="logo">MetisLLM</div>
      <div className="header-right">
        <div className="user-profile">
          <div className="user-info">
            <span className="user-name">{username}</span>
            <span className="user-role">{role}</span>
          </div>
          <button
            onClick={handleLogout}
            className="logout-button"
            title="Logout"
          >
            <LogOut size={18} />
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
