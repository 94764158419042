import React from "react";
import "../styles/MonthSlider.css";

interface MonthSliderProps {
  currentMonth: number;
  onChange: (newMonth: number) => void;
  isFilterEnabled: boolean;
  onToggleFilter: () => void;
}

const MonthSlider: React.FC<MonthSliderProps> = ({
  currentMonth,
  onChange,
  isFilterEnabled,
  onToggleFilter,
}) => {
  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value, 10);
    onChange(newValue);
  };

  return (
    <div className="metric-container">
      <div className="metric-header">
        <span className="metric-subtitle">Trading Calls</span>
      </div>
      <div className="slider-container">
        <input
          type="range"
          id="month-slider"
          min="0"
          max="24"
          value={currentMonth}
          onChange={handleSliderChange}
          className="styled-slider"
        />
        <div className="slider-value">
          <span
            className={`slider-badge ${!isFilterEnabled ? "disabled" : ""}`}
            onClick={onToggleFilter}
          >
            {currentMonth} Month(s)
          </span>
        </div>
      </div>
    </div>
  );
};

export default MonthSlider;
