import { useEffect, useState } from "react";

type TableJson = Record<string, Array<Record<string, string>>>;

const CACHE_KEY = "metricTableData";
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

const getApiUrl = (baseUrl: string, userEmail: string) => {
  return `${baseUrl}/users/${userEmail}/metrics`;
};

const useMetricTableApi = (
  baseUrl: string,
  userEmail: string,
  refreshKey: number
) => {
  const [metricTables, setMetricTables] = useState<TableJson>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const cachedData = localStorage.getItem(CACHE_KEY);
      if (cachedData) {
        const { timestamp, data } = JSON.parse(cachedData);
        const now = Date.now();
        if (now - timestamp < CACHE_DURATION) {
          setMetricTables(data);
          setLoading(false);
          return;
        }
      }
      try {
        const url = getApiUrl(baseUrl, userEmail);
        const response = await fetch(url);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const rawData = await response.json();
        setMetricTables(rawData);
        localStorage.setItem(
          CACHE_KEY,
          JSON.stringify({ timestamp: Date.now(), data: rawData })
        );
      } catch (error) {
        console.log(error);
        setError(error instanceof Error ? error.message : String(error));
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [baseUrl, userEmail, refreshKey]);

  return { metricTables, loading, error };
};

export default useMetricTableApi;
