import React from "react";
import { useState } from "react";
import TableFieldFilter from "./Table/TableFieldFilter";
import "../styles/Table.css";
import {
  SiBankofamerica,
  SiGoldmansachs,
  SiWellsfargo,
  SiBarclays,
  SiDeutschebank,
  SiChase,
} from "react-icons/si";
import CitiLogo from "../assets/citi.png";
import MorganStanley from "../assets/morgan-stanley.png";
import CreditSuisse from "../assets/credit-suisse.png";
import UBS from "../assets/ubs.png";
import HSBC from "../assets/hsbc.png";
import Nomura from "../assets/nomura.png";

interface FilterState {
  qualifiers: string[];
  dateRange: {
    startDate: string;
    endDate: string;
  };
}

interface TableProps {
  custom_css?: string;
  data: Array<Record<string, any>>;
  columns: string[];
  onRowClick: (citationId: string) => void;
  filterDate?: string;
  isFilterEnabled?: boolean;
  monthsAhead?: number;
  rowRenderer?: (row: Record<string, any>, rowIndex: number) => JSX.Element;
}

const skip_columns = [
  "link",
  "unit",
  "direction",
  "source_text",
  "page_number",
];
const ICON_SIZE = 20;

const directionMapping: Record<string, string> = {
  VALUE_CHANGE_INCREASE: "↑",
  VALUE_CHANGE_DECREASE: "↓",
  "": "",
};

const unitMapping: Record<string, string> = {
  UNIT_PERCENTAGE: "%",
  UNIT_DOLLAR: "$",
  UNIT_BPS: "BPS",
  "": "",
};

const getFirmIcon = (firm: string) => {
  switch (firm) {
    case "JPMorgan Chase":
      return <SiChase size={ICON_SIZE} />;
    case "Bank of America":
      return <SiBankofamerica size={ICON_SIZE} />;
    case "Goldman Sachs":
      return <SiGoldmansachs size={ICON_SIZE} />;
    case "Morgan Stanley":
      return (
        <img
          src={MorganStanley}
          alt="Morgan Stanley"
          style={{ width: ICON_SIZE + 5, height: ICON_SIZE }}
        />
      );
    case "Wells Fargo":
      return <SiWellsfargo size={ICON_SIZE} />;
    case "Citigroup":
      return (
        <img
          src={CitiLogo}
          alt="Citigroup"
          style={{ width: ICON_SIZE, height: ICON_SIZE }}
        />
      );
    case "Barclays":
      return <SiBarclays size={ICON_SIZE} />;
    case "Deutsche Bank":
      return <SiDeutschebank size={ICON_SIZE} />;
    case "Credit Suisse":
      return (
        <img
          src={CreditSuisse}
          alt="Credit Suisse"
          style={{ width: ICON_SIZE, height: ICON_SIZE }}
        />
      );
    case "UBS":
      return (
        <img
          src={UBS}
          alt="UBS"
          style={{ width: ICON_SIZE, height: ICON_SIZE }}
        />
      );
    case "HSBC":
      return (
        <img
          src={HSBC}
          alt="HSBC"
          style={{ width: ICON_SIZE, height: ICON_SIZE }}
        />
      );
    case "Nomura":
      return (
        <img
          src={Nomura}
          alt="Nomura"
          style={{ width: ICON_SIZE, height: ICON_SIZE }}
        />
      );
    default:
      return (
        <i className="fas fa-piggy-bank" style={{ fontSize: ICON_SIZE }}></i>
      );
  }
};

const capitalizeWords = (str: string) => {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const handleMetricValue = (
  value: string,
  unit: string,
  direction: string
): JSX.Element => {
  const unitStr = unitMapping[unit] || "";
  const directionStr = directionMapping[direction] || "";
  const directionClass =
    direction === "VALUE_CHANGE_INCREASE"
      ? "metric-increase"
      : direction === "VALUE_CHANGE_DECREASE"
        ? "metric-decrease"
        : "";

  return (
    <span className={directionClass}>
      {value} {unitStr} {directionStr}
    </span>
  );
};

const Table: React.FC<TableProps> = ({
  custom_css,
  data,
  columns,
  onRowClick,
  filterDate = "",
  isFilterEnabled = false,
  monthsAhead = 0,
  rowRenderer,
}) => {
  const [filters, setFilters] = useState<FilterState>({
    qualifiers: [],
    dateRange: {
      startDate: "",
      endDate: "",
    },
  });

  // Sort data by date
  const sortedData = [...data].sort((a, b) => {
    const dateA = new Date(a.target_date);
    const dateB = new Date(b.target_date);
    return dateB.getTime() - dateA.getTime();
  });

  // Apply all filters
  const filteredData = sortedData.filter((row) => {
    // Apply qualifier filter
    if (
      filters.qualifiers.length > 0 &&
      !filters.qualifiers.includes(row.qualifier)
    ) {
      return false;
    }

    // Apply date range filter
    if (filters.dateRange.startDate || filters.dateRange.endDate) {
      const rowDate = new Date(row.target_date);

      if (filters.dateRange.startDate) {
        const startDate = new Date(filters.dateRange.startDate);
        if (rowDate < startDate) return false;
      }

      if (filters.dateRange.endDate) {
        const endDate = new Date(filters.dateRange.endDate);
        if (rowDate > endDate) return false;
      }
    }

    // Apply existing date filter if enabled
    if (isFilterEnabled) {
      const targetDate = new Date(row.target_date);
      const selectedDate = new Date(filterDate);
      const today = new Date();

      if (monthsAhead < 0) {
        if (targetDate > selectedDate) return false;
      }

      if (monthsAhead > 0) {
        const maxDate = new Date(today);
        maxDate.setMonth(maxDate.getMonth() + monthsAhead);
        if (targetDate < today || targetDate > maxDate) return false;
      }

      if (monthsAhead === 0 && targetDate > selectedDate) return false;
    }

    return true;
  });

  const handleFilterChange = (newFilters: FilterState) => {
    setFilters(newFilters);
  };

  const containerClassName = custom_css
    ? `${custom_css}-container`
    : "table-container";
  const tableClassName = custom_css ? custom_css : "styled-table";

  return (
    <div>
      <div className="table-controls">
        <TableFieldFilter data={data} onFilterChange={handleFilterChange} />
      </div>

      <div className={containerClassName}>
        <table className={tableClassName}>
          <thead>
            <tr>
              {columns
                .filter((col) => !skip_columns.includes(col))
                .map((col) => (
                  <th key={col}>{capitalizeWords(col.replace("_", " "))}</th>
                ))}
            </tr>
          </thead>
          <tbody>
            {filteredData.map((row, rowIndex) =>
              rowRenderer ? (
                rowRenderer(row, rowIndex)
              ) : (
                <tr
                  key={rowIndex}
                  onClick={() => onRowClick(row["link"])}
                  className="clickable-row"
                  title={
                    row["source_text"] + "\npage number: " + row["page_number"]
                  }
                >
                  {columns
                    .filter((col) => !skip_columns.includes(col))
                    .map((col) => {
                      const cellValue = row[col];
                      if (col === "firm") {
                        return (
                          <td key={col} className="firm-column">
                            {getFirmIcon(cellValue)}
                            <span style={{ marginLeft: "8px" }}>
                              {cellValue}
                            </span>
                          </td>
                        );
                      }

                      if (col === "metric_value") {
                        const unit = row["unit"];
                        const direction = row["direction"];

                        return (
                          <td key={col}>
                            {handleMetricValue(cellValue, unit, direction)}
                          </td>
                        );
                      }
                      if (col === "qualifier") {
                        const qualifier = row["qualifier"];
                        return <td key={col}>{qualifier || ""}</td>;
                      }
                      return <td key={col}>{cellValue || ""}</td>;
                    })}
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
