import React, { useState, useContext } from "react";
import "../styles/ThesisTool.css";
import { BaseUrlContext } from "../Contexts";
import { useAuth } from "../context/AuthContext";
import ReactMarkdown from "react-markdown";

const sendMessageToApi = async (
  thesisMessage: string,
  baseUrl: string,
  userEmail: string
): Promise<string> => {
  const response = await fetch(`${baseUrl}/users/${userEmail}/thesis`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ thesis: thesisMessage }),
  });

  if (!response.ok) {
    throw new Error(`Failed to send message: ${response.statusText}`);
  }

  const responseData = await response.json();
  return responseData.response;
};

const ThesisTool = () => {
  const [thesisInput, setThesisInput] = useState("");
  const [markdownResponse, setMarkdownResponse] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const baseUrl = useContext(BaseUrlContext);
  const { username } = useAuth();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!thesisInput.trim()) return;

    setIsLoading(true);
    setError(null);

    try {
      const responseMessage = await sendMessageToApi(
        thesisInput.trim(),
        baseUrl,
        username
      );
      setMarkdownResponse(responseMessage);
      setThesisInput("");
    } catch (err) {
      setError(
        err instanceof Error
          ? err.message
          : "An error occurred while sending the message"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    setMarkdownResponse(null);
    setThesisInput("");
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      const form = e.currentTarget.form;
      if (form) form.requestSubmit();
    }
  };

  return (
    <div className="thesis-tool-wrapper">
      <div className="thesis-header">
        <h1>📝 Thesis Tool</h1>
      </div>

      <div className="thesis-content">
        {markdownResponse ? (
          <div className="markdown-view">
            <ReactMarkdown>{markdownResponse}</ReactMarkdown>
            <div className="thesis-footer">
              <button className="thesis-action-button" onClick={handleReset}>
                Reset
              </button>
            </div>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="input-form-thesis">
            <div>
              <textarea
                className="thesis-textarea"
                placeholder="Start writing your analysis here..."
                value={thesisInput}
                onChange={(e) => setThesisInput(e.target.value)}
                onKeyDown={handleKeyDown}
                disabled={isLoading}
                rows={3}
              />
            </div>
            <div className="thesis-footer">
              <button
                type="submit"
                className="thesis-action-button"
                disabled={isLoading || thesisInput.trim().length === 0}
              >
                Submit Thesis
              </button>
              <button
                type="button"
                className="thesis-reset-button"
                onClick={handleReset}
                disabled={isLoading}
              >
                Reset
              </button>
            </div>
          </form>
        )}

        {isLoading && <div className="loading-spinner"></div>}
        {error && <div className="error-message">Error: {error}</div>}
      </div>
    </div>
  );
};

export default ThesisTool;
