import React, { useState, useContext } from "react";
import { ChevronDown, ChevronUp } from "lucide-react";
import { BaseUrlContext } from "../Contexts";
import { useAuth } from "../context/AuthContext";
import useKeyThemesApi from "../hooks/useKeyThemesApi";
import "../styles/KeyThemes.css";

interface TableEntry {
  pdf_name: string;
  link: string;
}

const ITEMS_PER_PAGE = 5;

const capitalizeWords = (str: string) => {
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
};

const KeyThemes: React.FC = () => {
  const [expandedTheme, setExpandedTheme] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const baseUrl = useContext(BaseUrlContext);
  const { username: userEmail } = useAuth();

  const { keyThemes, loading, error, fetchPdf, fetchingPdf } = useKeyThemesApi(
    baseUrl,
    userEmail
  );

  if (loading) {
    return <div className="key-themes-container">Loading key themes...</div>;
  }

  if (error) {
    return (
      <div className="key-themes-container">
        Error loading key themes: {error}
      </div>
    );
  }

  const themeEntries = Object.entries(keyThemes);
  const totalPages = Math.ceil(themeEntries.length / ITEMS_PER_PAGE);
  const startIndex = currentPage * ITEMS_PER_PAGE;
  const visibleThemes = themeEntries.slice(
    startIndex,
    startIndex + ITEMS_PER_PAGE
  );

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage((curr) => curr + 1);
      setExpandedTheme(null);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage((curr) => curr - 1);
      setExpandedTheme(null);
    }
  };

  const toggleTheme = (index: number) => {
    setExpandedTheme(expandedTheme === index ? null : index);
  };

  return (
    <div className="key-themes-container">
      <h2 className="key-themes-title">
        🔍 Key Themes: Most Discussed In This Week's Research
      </h2>
      <div className="themes-list">
        {visibleThemes.map(([theme, entries], index) => (
          <div key={startIndex + index} className="theme-item">
            <button
              className="theme-button"
              onClick={() => toggleTheme(startIndex + index)}
            >
              <span>{capitalizeWords(theme)}</span>
              {expandedTheme === startIndex + index ? (
                <ChevronUp className="chevron-icon" />
              ) : (
                <ChevronDown className="chevron-icon" />
              )}
            </button>
            <div
              className={`theme-content ${
                expandedTheme === startIndex + index ? "expanded" : ""
              }`}
            >
              <div className="theme-content-inner">
                <table className="pdf-table">
                  <tbody>
                    {entries.map((entry: TableEntry, pdfIndex: number) => (
                      <tr key={pdfIndex}>
                        <td>
                          <button
                            onClick={() => fetchPdf(entry.pdf_name)}
                            className="pdf-link-button"
                            disabled={fetchingPdf === entry.pdf_name}
                          >
                            {entry.pdf_name}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="pagination-controls">
        <button
          onClick={handlePrevPage}
          disabled={currentPage === 0}
          className={`pagination-button ${currentPage === 0 ? "disabled" : ""}`}
        >
          Previous 5
        </button>

        <span className="pagination-info">
          Page {currentPage + 1} of {totalPages}
        </span>

        <button
          onClick={handleNextPage}
          disabled={currentPage >= totalPages - 1}
          className={`pagination-button ${
            currentPage >= totalPages - 1 ? "disabled" : ""
          }`}
        >
          Next 5
        </button>
      </div>
    </div>
  );
};

export default KeyThemes;
