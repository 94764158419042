import React, { useState } from "react";
import Dashboard from "./Dashboard";
import Chat from "./ChatPage/Chat";
import TableRelationship from "./TableRelationship";
import KnowledgeGraph from "./KnowledgeGraph";
import FileUploader from "./FileUploader";
import ThesisTool from "./ThesisTool";
import { ChatProvider } from "../context/ChatContext";
import { Grid, Bot, Table, Search } from "lucide-react";
import "../styles/Navigation.css";

const Navigation: React.FC = () => {
  const [activeComponent, setActiveComponent] = useState("Dashboard");
  const [showFileUploader, setShowFileUploader] = useState(false);

  const navItems = [
    { name: "Dashboard", icon: Grid },
    { name: "AskMETIS", icon: Bot },
    { name: "Metrics Table", icon: Table },
    { name: "Knowledge Graph", icon: Search },
    { name: "Thesis Tool", icon: Search },
  ];

  const renderComponent = () => {
    switch (activeComponent) {
      case "Dashboard":
        return <Dashboard />;
      case "AskMETIS":
        return <Chat />;
      case "Metrics Table":
        return <TableRelationship />;
      case "Knowledge Graph":
        return <KnowledgeGraph />;
      case "Thesis Tool":
        return <ThesisTool />;
      default:
        return <Dashboard />;
    }
  };

  const toggleFileUploader = () => {
    setShowFileUploader(!showFileUploader);
  };


  return (
    <ChatProvider>
      <div>
        <nav className="navigation">
          {navItems.map((item) => (
            <button
              key={item.name}
              className={`nav-button ${
                activeComponent === item.name ? "active" : ""
              }`}
              onClick={() => setActiveComponent(item.name)}
            >
              <item.icon className="nav-icon" />
              {item.name}
            </button>
          ))}

          <button className="nav-button upload" onClick={toggleFileUploader}>
            Upload Files
          </button>
        </nav>

        <div className="component-container">{renderComponent()}</div>

        <div
          className={`file-uploader-container ${
            showFileUploader ? "show" : "hide"
          }`}
        >
          {showFileUploader && <FileUploader onClose={toggleFileUploader} />}
        </div>
      </div>
    </ChatProvider>
  );
};

export default Navigation;
