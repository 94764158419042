import React, { useState, useContext, useEffect } from "react";
import Card from "./Card";
import Table from "./Table";
import MonthSlider from "./MonthSlider";
import KeyThemes from "./KeyThemes";
import RecentChangesTable from "./RecentChangesTable";
import TradeReccomendations from "./TradeRecommendations";
import "../styles/Dashboard.css";
import { BaseUrlContext } from "../Contexts";
import { useAuth } from "../context/AuthContext";
import useMetricTableApi from "../hooks/useMetricTableApi";
import useFetchPdfByCitationID from "../hooks/useFetchPdfByCitationID";
import { addMonths, format } from "date-fns";

const Dashboard: React.FC = () => {
  const userEmail: string = useAuth().username;
  const baseUrl = useContext(BaseUrlContext);

  const [refreshKey, setRefreshKey] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedCitationId, setSelectedCitationId] = useState<string | null>(
    null
  );
  const [monthsAhead, setMonthsAhead] = useState<number>(0); // Start with showing all data
  const [filterEnabled, setFilterEnabled] = useState<boolean>(false); // Track if filtering should be enabled
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const tablesPerPage = 6;

  const {
    pdfBlobUrl,
    loading: pdfLoading,
    pageNumber,
    error: pdfError,
  } = useFetchPdfByCitationID(baseUrl, userEmail, selectedCitationId);

  const {
    metricTables,
    loading: dataLoading,
    error,
  } = useMetricTableApi(baseUrl, userEmail, refreshKey);

  const handleRefresh = () => {
    setLoading(true);
    localStorage.removeItem("metricTableData");
    localStorage.removeItem("pdfCache");
    localStorage.removeItem("tradeIdeasData");
    localStorage.removeItem("callChangesData");
    setRefreshKey((prevKey) => prevKey + 1);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const getColumns = (data: Array<Record<string, string>>) => {
    return data.length > 0 ? Object.keys(data[0]) : [];
  };

  const handleNext = () => {
    if (
      metricTables &&
      currentTabIndex + tablesPerPage < Object.keys(metricTables).length
    ) {
      setCurrentTabIndex((prev) => prev + tablesPerPage);
    }
  };

  const handlePrev = () => {
    if (currentTabIndex > 0) {
      setCurrentTabIndex((prev) => prev - tablesPerPage);
    }
  };

  const handleCitationClick = (citationId: string) => {
    setSelectedCitationId(citationId);
  };

  useEffect(() => {
    if (pdfBlobUrl && pageNumber !== null) {
      const pdfUrlWithPage = `${pdfBlobUrl}#page=${pageNumber}`;
      const pdfWindow = window.open();
      if (pdfWindow) {
        pdfWindow.location.href = pdfUrlWithPage;
        pdfWindow.focus();
      }
    }
  }, [pdfBlobUrl, pageNumber]);

  const metricKeys = metricTables ? Object.keys(metricTables) : [];
  const displayedMetrics = metricKeys.slice(
    currentTabIndex,
    currentTabIndex + tablesPerPage
  );

  // Adjust target date for filtering based on the slider's value (past or future)
  const targetDate = format(addMonths(new Date(), monthsAhead), "yyyy-MM-dd");

  // Handle slider change and enable filtering
  const handleMonthChange = (newMonth: number) => {
    setMonthsAhead(newMonth);
    setFilterEnabled(true); // Enable filtering once the user changes the slider
  };

  const toggleFilter = () => {
    setFilterEnabled((prev) => !prev);
  };

  const renderMetricTable = (
    metricName: string,
    metricData: Array<Record<string, string>>
  ) => (
    <Card
      title={metricName}
      body={`What are the dealers' ${metricName} calls as of ${new Date().toLocaleDateString()}?`}
    >
      {metricData && (
        <Table
          custom_css=""
          data={metricData}
          columns={getColumns(metricData)}
          onRowClick={handleCitationClick}
          filterDate={targetDate}
          monthsAhead={monthsAhead} // Pass monthsAhead to determine past/future filtering
          isFilterEnabled={filterEnabled}
        />
      )}
    </Card>
  );

  if (dataLoading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
        <p>Loading dashboard data, please wait...</p>
      </div>
    );
  }

  if (error) {
    return <p>Error loading data</p>;
  }

  return (
    <div className="dashboard">
      <div className="controls-container">
        <div className="filter-controls-left">
          <MonthSlider
            currentMonth={monthsAhead}
            onChange={handleMonthChange}
            isFilterEnabled={filterEnabled}
            onToggleFilter={toggleFilter}
          />
        </div>

        <div className="table-navigation">
          <button
            className="nav-button"
            onClick={handlePrev}
            disabled={currentTabIndex === 0}
          >
            ←
          </button>
          <button
            className="nav-button"
            onClick={handleNext}
            disabled={currentTabIndex + tablesPerPage >= metricKeys.length}
          >
            →
          </button>
          <button
            className={`refresh-button ${loading ? "loading" : ""}`}
            onClick={handleRefresh}
            disabled={loading}
          >
            {loading ? <span className="refresh-icon"></span> : "Refresh"}
          </button>
        </div>
      </div>

      <div className="cards-grid">
        {displayedMetrics.map((metricName) =>
          renderMetricTable(metricName, metricTables[metricName])
        )}
      </div>
      <KeyThemes />
    </div>
  );
};

export default Dashboard;
