import React, { useState, useEffect } from "react";
import { Check, Calendar } from "lucide-react";
import "../../styles/TableFieldFilter.css";

interface TableFieldFilterProps {
  data: Array<Record<string, any>>;
  onFilterChange: (filters: FilterState) => void;
}

interface FilterState {
  qualifiers: string[];
  dateRange: {
    startDate: string;
    endDate: string;
  };
}

type TabType = "qualifier" | "date";

const TableFieldFilter: React.FC<TableFieldFilterProps> = ({
  data,
  onFilterChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState<TabType>("qualifier");
  const [qualifiers, setQualifiers] = useState<Set<string>>(new Set());
  const [filterState, setFilterState] = useState<FilterState>({
    qualifiers: [],
    dateRange: {
      startDate: "",
      endDate: "",
    },
  });

  // Get min and max dates from data
  const dates = data
    .map((item) => new Date(item.target_date))
    .sort((a, b) => a.getTime() - b.getTime());
  const minDate = dates[0]?.toISOString().split("T")[0] || "";
  const maxDate = dates[dates.length - 1]?.toISOString().split("T")[0] || "";

  useEffect(() => {
    const uniqueQualifiers = new Set(
      data
        .map((item) => item.qualifier)
        .filter(
          (qualifier): qualifier is string =>
            qualifier !== undefined && qualifier !== null && qualifier !== ""
        )
    );
    setQualifiers(uniqueQualifiers);
  }, [data]);

  const handleQualifierToggle = (qualifier: string) => {
    const newQualifiers = filterState.qualifiers.includes(qualifier)
      ? filterState.qualifiers.filter((q) => q !== qualifier)
      : [...filterState.qualifiers, qualifier];

    const newFilterState = {
      ...filterState,
      qualifiers: newQualifiers,
    };

    setFilterState(newFilterState);
    onFilterChange(newFilterState);
  };

  const handleDateChange = (type: "startDate" | "endDate", value: string) => {
    const newFilterState = {
      ...filterState,
      dateRange: {
        ...filterState.dateRange,
        [type]: value,
      },
    };

    setFilterState(newFilterState);
    onFilterChange(newFilterState);
  };

  const clearFilters = () => {
    const newFilterState = {
      qualifiers: [],
      dateRange: {
        startDate: "",
        endDate: "",
      },
    };
    setFilterState(newFilterState);
    onFilterChange(newFilterState);
  };

  const getActiveFiltersCount = () => {
    const qualifierCount = filterState.qualifiers.length;
    const dateCount =
      (filterState.dateRange.startDate ? 1 : 0) +
      (filterState.dateRange.endDate ? 1 : 0);
    return qualifierCount + dateCount;
  };

  return (
    <div className="qualifier-filter">
      <div className="filter-button-container">
        <button
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          className="filter-button"
        >
          Filter
          {getActiveFiltersCount() > 0 && (
            <span className="filter-counter">{getActiveFiltersCount()}</span>
          )}
        </button>
      </div>

      {isOpen && (
        <div className="dropdown-menu">
          <div className="dropdown-content">
            <div className="tabs">
              <button
                className={`tab ${activeTab === "qualifier" ? "active" : ""}`}
                onClick={() => setActiveTab("qualifier")}
              >
                Qualifiers
              </button>
              <button
                className={`tab ${activeTab === "date" ? "active" : ""}`}
                onClick={() => setActiveTab("date")}
              >
                Date Range
              </button>
            </div>

            <div className="dropdown-header">
              <span className="header-title">Filters</span>
              {getActiveFiltersCount() > 0 && (
                <button onClick={clearFilters} className="clear-button">
                  Clear all
                </button>
              )}
            </div>

            {activeTab === "qualifier" ? (
              <div className="qualifier-list">
                {Array.from(qualifiers).map((qualifier) => (
                  <div
                    key={qualifier}
                    className="qualifier-item"
                    onClick={() => handleQualifierToggle(qualifier)}
                  >
                    <div className="checkbox">
                      {filterState.qualifiers.includes(qualifier) && (
                        <Check className="check-icon" />
                      )}
                    </div>
                    <span className="qualifier-label">{qualifier}</span>
                  </div>
                ))}
              </div>
            ) : (
              <div className="date-filter">
                <div className="date-input-group">
                  <label>Start Date</label>
                  <div className="date-input-wrapper">
                    <Calendar size={14} />
                    <input
                      type="date"
                      value={filterState.dateRange.startDate}
                      onChange={(e) =>
                        handleDateChange("startDate", e.target.value)
                      }
                      min={minDate}
                      max={filterState.dateRange.endDate || maxDate}
                    />
                  </div>
                </div>
                <div className="date-input-group">
                  <label>End Date</label>
                  <div className="date-input-wrapper">
                    <Calendar size={14} />
                    <input
                      type="date"
                      value={filterState.dateRange.endDate}
                      onChange={(e) =>
                        handleDateChange("endDate", e.target.value)
                      }
                      min={filterState.dateRange.startDate || minDate}
                      max={maxDate}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TableFieldFilter;
