import React, { useState, useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import "../styles/LoginPage.css";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { Loader2 } from "lucide-react";

const LoginPage: React.FC<{ onLoginSuccess?: () => void }> = ({
  onLoginSuccess,
}) => {
  const {
    oauthLogin,
    emailLogin,
    checkEmailAllowed,
    isAllowed,
    loading,
    error,
    isAuthenticated,
  } = useAuth();

  const [googleEmail, setGoogleEmail] = useState<string | null>(null);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (isAuthenticated && onLoginSuccess) {
      onLoginSuccess();
    }
  }, [isAuthenticated, onLoginSuccess]);

  const handleGoogleLogin = (credentialResponse: any) => {
    try {
      const token = credentialResponse.credential;
      const decodedToken: any = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp < currentTime) {
        setErrorMessage("Google token has expired, please try again.");
        return;
      }

      const email = decodedToken.email;
      setGoogleEmail(email);
      checkEmailAllowed(email);
    } catch (error) {
      console.error("Failed to decode Google token:", error);
      setErrorMessage(
        "An error occurred during Google login. Please try again."
      );
    }
  };

  const handleEmailLogin = async () => {
    if (!email || !password) {
      setErrorMessage("Please enter both email and password");
      return;
    }

    try {
      await emailLogin(email, password);
    } catch (error) {
      setErrorMessage("Email login failed. Please check your credentials.");
      console.error("Email login error:", error);
    }
  };

  useEffect(() => {
    if (isAllowed === true && googleEmail) {
      oauthLogin("google", googleEmail, "User");
    } else if (isAllowed === false) {
      setErrorMessage("Google email is not allowed for login.");
    }
  }, [isAllowed, googleEmail, oauthLogin]);

  if (loading) {
    return (
      <div className="login-container">
        <div className="login-card loading-card">
          <Loader2 className="loading-spinner" />
          <h2 className="loading-text">Logging In...</h2>
        </div>
      </div>
    );
  }

  return (
    <div className="login-container">
      <div className="login-card">
        <h2>MetisLLM Login</h2>
        <div className="email-login">
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            className="login-btn"
            onClick={handleEmailLogin}
            disabled={loading}
          >
            {loading ? (
              <span className="button-loading">
                <Loader2 className="button-spinner" />
                Logging in...
              </span>
            ) : (
              "Login"
            )}
          </button>
        </div>
        <div className="oauth-login">
          <GoogleLogin
            onSuccess={handleGoogleLogin}
            onError={() => {
              console.error("Google Login Failed");
              setErrorMessage("Google Login Failed. Please try again.");
            }}
          />
        </div>
        {(errorMessage || error) && (
          <p className="error-message">{errorMessage || error}</p>
        )}
      </div>
    </div>
  );
};

export default LoginPage;
